@import "_variables";
@import "_mixins";

$h1: px-em(134px);
$h2: px-em(98px);
$h3: px-em(75px); //maybe 56 if we want to do 75%
$h4: px-em(36px);
$h5: 1em;
$h6: 0.5em;

$baseScale: 1;

//
// Typography
// --------------------------------------------------
// Customize this to meet your project needs


// Body text
// -------------------------

html {
  font-size: 16px;

  @media (max-width: px-em($grid-mobile-breakpoint)) {
  }
}

body {
  @include fontRegular;
  color: $defaultTextColor;
  line-height: $baseLineHeight;
}

p {
  font-family: $baseFontFamily;
  font-weight: $bodyFontNormalWeight;
  font-style: normal;
  color: $white;
  b, strong {
    font-weight: $bodyFontBoldWeight;
    & em {
      font-style: italic;
    }
  }
  em {
    font-style: italic;
    & b, & strong {
      font-weight: $bodyFontBoldWeight;
    }
  }

}

strong, b {
  font-weight: $bodyFontBoldWeight;
}

// Links
// -------------------------

a {
  color: $linkColor;
  text-decoration: none;
  transition: color $transitionFast linear;
}
a:hover,
a:active,
a:focus {
  color: $linkColorHover;
  text-decoration: none;
}



h1, h2, h3, h4, h5, h6, .t1, .t2, .t3, .t4, .t5, .t6 {
  text-rendering: optimizeLegibility; // disable this if we have performance issues.
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  line-height: 0.9;
  margin: 0;
  font-weight: $headingBoldWeight;
  color: $white;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $headingsFontFamily;
  // letter-spacing: 4px;
  margin-bottom: 18px;
}


.t {
  text-rendering: optimizeLegibility; // disable this if we have performance issues.
  -webkit-font-smoothing: antialiased;
  letter-spacing: 1px;
}

h1 {
  font-size: $h1 * $baseScale;
}
h2 {
  font-size: $h2 * $baseScale;
}
h3 {
  font-size: $h3 * $baseScale;
  line-height: 1;
}
h4 {
  font-size: $h4 * $baseScale;
}
h5 {
  font-size: $h5 * $baseScale;
}
h6 {
  font-size: $h6 * $baseScale;
}

.t1 {
  font-size: $h1 * $baseScale;
}
.t2 {
  font-size: $h2 * $baseScale;
}
.t3 {
  font-size: $h3 * $baseScale;
  line-height: 1;
}
.t4 {
  font-size: $h4 * $baseScale;
}
.t5 {
  font-size: $h5 * $baseScale;
}
.t6 {
  font-size: $h6 * $baseScale;
}

// .p1, .p2, .p3 {
//   font-weight: $bodyFontNormalWeight;
// }

p, .p1 {
  font-size: px-em(18px * $baseScale);
  // line-height: px-em(40px * $baseScale, 29px * $baseScale);
  // line-height: 1;
  margin-bottom: px-em(22px * $baseScale, 22px * $baseScale);
}

.p2 {
  font-size: px-em(16px * $baseScale);
  margin-bottom: px-em(22px * $baseScale, 22px * $baseScale);
  // line-height: px-em(34px * $baseScale, 25px * $baseScale);
  // margin-bottom: px-em(34px * $baseScale, 25px * $baseScale);
}

.p3 {
  font-size: px-em(12px * $baseScale);
  line-height: px-em(18px * $baseScale, 12px * $baseScale);
  margin-bottom: px-em(12px * $baseScale, 12px * $baseScale);
}

.content p {
  font-size: 100%;
}

@media #{$mobile} {
  p, .p1 {
    font-size: px-em(16px);
  }
  .p2 {
    font-size: px-em(14px);
  }
}
