@import "./../styles/_variables";
@import "./../styles/_mixins";
.selection-logo {
  position: absolute;
  bottom: 20px;
  left: 20px;
  .logo {
    width: 178px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  p {
    font-weight: bold;
    // font-size: px-em(22px);
    // font-size: 25px;
    margin-bottom: 0;
  }
}