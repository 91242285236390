.video {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    video {
        height: 100%;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
    }
    &.blurred {
        video {
            filter: blur(10px);
        }
    }
}