@import "./../styles/_variables";
@import "./../styles/_mixins";
.preloader {
  width: 100%;
  height: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .label {
      width: 100%;
      text-align: center;
      font-size: px-em(20px);
      font-weight: $bodyFontBoldWeight;
      margin-bottom: 12px;
  }
}