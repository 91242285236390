@import "./../styles/_variables";
@import "./../styles/_mixins";
.landing {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    // background-image: url(./../assets/images/gradient-bg@2x.png);
    // background-size: cover;
    top: 0;
    left: 0;
    color: white;
    .animated {
        opacity: 0;
        transform: translateY(20px);
        transition: all 0.5s;
    }
    .title {
        @include consistent-margin-thingy(142px, 90px);
        .line-one {
            font-size: 30px;
            margin-bottom: 8px;
            font-weight: $bodyFontBoldWeight;
        }
        .line-two {
            width: 238px;
        }
        // margin-bottom: 80px;
    }
    .copy {
        transition-delay: 0.2s;
        font-size: 20px;
        // margin: 0 auto 120px;
        @include consistent-margin-thingy(77px, 90px);
        // width: 250px;
        line-height: 1.5
    }
    .button {
        transition-delay: 0.4s;
        position: absolute;
        bottom: 58px;
        left: 45px;
    }
    &.in {
        .animated {
            opacity: 1;
            transform: translateY(0);
        }
    }
}