.progress {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    $size: 50px;
    $numIndicators: 6;
    $gap: 20px;
    text-align: center;
    // padding-top: 20px;
    .indicators {
        display: flex;
        justify-content: space-evenly;
        max-width: ($size + $gap) * $numIndicators;
        margin: 0 auto;
    }
    .indicator {
        position: relative;
        width: $size;
        height: $size;
        border-radius: $size;
        background: linear-gradient(0deg, rgba(118,33,179,1) 0%, rgba(50,18,92,1) 100%);
        box-shadow:0 0 8px rgba(0, 0, 0, 0.5);
        transition: border 0.3s;
        .q-mark {
            width: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.3s;
        }
        .selected-image {
            transition: all 0.3s;
            visibility: hidden;
            position: absolute;
            top: 50%;
            left: 50%;
            max-width: $size * 1.6;
            transform: translate(-50%, -50%) scale(0.1);

        }
        &.activated {
            .q-mark {
                opacity: 0;
                transform: translate(-50%, -50%) scale(0.1);
            }
            border: 3px solid white;
            &:after {
                display: none;
            }
            .selected-image {
                visibility: visible;
                transform: translate(-50%, -50%);
            }
        }
    }
}