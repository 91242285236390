@import "_variables";
@import "_mixins";

.button {
  font-size: px-em(20px);
  padding: 18px;
  border: 2px solid white;
  display: inline-block;
  color: $buttonLabelColor;
  font-weight: $bodyFontBoldWeight;
  background-color: $white;
  border-radius: 60px;
  // min-width: 300px;
  width: calc(100% - 90px);
}