@import "./../styles/_variables";
@import "./../styles/_mixins";

.rotate {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  // right: 0;
  // bottom: 0;
  background-image: url(./../assets/images/gradient-bg@2x.png);
  background-size: cover;
  text-align: center;
  // align-content: center;
  // flex-wrap: wrap;
  * {
    width: 100%;
  }
  // h1 {
  //   font-size: px-em(30px);
  // }
  p {
    position: absolute;
    font-size: px-em(22px);
    top: 50%;
    transform: translateY(-50%);
  }
  @media only screen and (orientation: landscape) {
    display: block;
  }
}