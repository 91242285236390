.reticle {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    circle {
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }
}