.background {
  background-image: url(./../assets/images/gradient-bg@2x.png);
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
  &.landing {
    opacity: 1;
  }
  &.instructions {
    opacity: 1;
    &.camera-on {
      opacity: 0.75;
    }
  }
}