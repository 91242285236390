@import "./../styles/_variables";
@import "./../styles/_mixins";

.instructions {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: white;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.75;
      background-image: url(./../assets/images/gradient-bg@2x.png);
      background-size: cover;
    }
    .centered-content {
      position: relative;
      width: 100%;
    }
    .animated {
        opacity: 0;
        transform: translateY(20px);
        transition: all 0.5s;
    }
    .title {
      font-size: px-em(30px);
      margin-bottom: 12px;
      font-weight: $bodyFontBoldWeight;
      @include consistent-margin-thingy(112px, 87px);
      // @include consistent-margin-thingy(90px, 87px); //safe for ios bad vh calc
    }
    .copy {
        font-size: px-em(20px);
        margin: 0 auto;
        // width: 250px;
        line-height: 1.5
    }
    .instructions-image-wrapper {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateY(53%);
    }
    .instructions-inner-wrapper {
      transform: translateY(-50%);
    }
    .instructions-image {
      position: relative;
      // @include consistent-margin-thingy(55px, 272px);
      // @include consistent-margin-thingy(20px, 272px); //safe for ios bad vh calc
      transition-delay: 0.2s;
      // top: 50%;
      // left: 50%;
      margin-top: 0;
      .main-image{
        width: 160px; //src 742 now it's 300, width was 350
        max-width: 20vh;
        position: relative;
      }
      .blob-image {
        position: absolute;
        width: 84px;
        // top: 31px;
        // left: 50%;
        // margin-left: -66px;
        top: 50%;
        left: 50%;
        margin-top: -41px;
        margin-left: -42px;
        transform: scale(0.1) transform(-25px, -78px);
        opacity: 0;
      }
    }
    .button {
      transition-delay: 0.4s;
      position: absolute;
      bottom: 58px;
      left: 45px;
      z-index: 100;
    }
    &.in {
        .animated {
            opacity: 1;
            transform: translateY(0);
        }
    }  
    @media only screen and (orientation: landscape) {
      &.in {
        .animated {
          opacity: 0;
          transition: none;
        }
      }
      .animated {
        opacity: 0;
        transition: none;
      }
    }
}