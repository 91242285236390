.experience {
    // height: 100%;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    // right: 0;
    // bottom: 0;
    .back-to-site {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        text-decoration: none;
        padding: 10px;
    }
    .loading {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: red;
        font-size: 20px;
        text-align: center;
    }
}