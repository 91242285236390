
$white: #FFFFFF;
$black: #000000;
$buttonLabelColor: #2059B2;

$defaultTextColor: $white;


$linkColor: $white;
$linkColorHover: darken($white, 10%);

$baseLineHeight: 1.25 !default;
$baseFontFamily: "att_aleck_sans", sans-serif;
$headingsFontFamily: "att_aleck_sans", sans-serif;

$bodyFontLightWeight: 400;
$bodyFontNormalWeight: 400;
$bodyFontBoldWeight: 600;

$headingLightWeight: 300;
$headingNormalWeight: 400;
$headingMediumWeight: 600;
$headingBoldWeight: 600;

// TRANSITIONS

$transitionSuperslow:   0.5s;
$transitionSlower:      0.4s;
$transitionSlow:        0.3s;
$transitionSlowish:     0.25s;
$transitionNormal:      0.2s;
$transitionFast:        0.15s;
$transitionFaster:      0.10s;
$transitionSuperfast:   0.05s;
$transitionFastest:     0.025s;


// these are based on Salesforce documentation
$grid-small-mobile-breakpoint: 340px;
$grid-mobile-breakpoint: 700px;
$grid-tablet-breakpoint: 980px;
$grid-max-width: 1140px;

$small-mobile: "only screen and (max-width : #{$grid-small-mobile-breakpoint})";
$mobile: "only screen and (max-width : #{$grid-mobile-breakpoint})";
$tablet: "only screen and (max-width : #{$grid-tablet-breakpoint})";
$tablet-only: "only screen and (min-width : #{$grid-mobile-breakpoint + 1}) and (max-width : #{$grid-tablet-breakpoint})";
$desktop: "only screen and (min-width : #{$grid-tablet-breakpoint + 1})";
$desktop-max: "only screen and (min-width: #{$grid-max-width})";
$retina: "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)";

$mobile-alt: "only screen and (max-width : 815px)";
$tablet-up: "only screen and (min-width : 816px)";