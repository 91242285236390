.scene {
    position: relative;
    width: 100%;
    height: 100%;
    // background-color: red;
    top: 0;
    left: 0;
    .threejs-canvas, .video-canvas {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}