/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 17, 2020 */



@font-face {
  font-family: 'att_aleck_sans';
  src: url('../assets/fonts/attalecksans-bold-webfont.woff2') format('woff2'),
       url('../assets/fonts/attalecksans-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-weight: 600;

}


@font-face {
  font-family: 'att_aleck_sans';
  src: url('../assets/fonts/attalecksans-light-webfont.woff2') format('woff2'),
       url('../assets/fonts/attalecksans-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-weight: 400;

}

@font-face {
  font-family: 'att_aleck_sans';
  src: url('../assets/fonts/attalecksans-italic-webfont.woff2') format('woff2'),
       url('../assets/fonts/attalecksans-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-weight: 400;

}
