@import "./../styles/_variables";
@import "./../styles/_mixins";

.finished-overlay {
  text-align: center;
  position: absolute;
  // top:  140px;
  // left: 21px;
  // width: calc(100% - 42px);
  // height: calc(var(--vh, 1vh) * 100 - 190px);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .background {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: absolute;
    opacity: 0.75;
    background-image: url(./../assets/images/gradient-bg@2x.png);
    background-size: cover;
  }
  .container {
    position: absolute;
    // padding: 80px 10px 50px;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
  }
  .logo {
    width: 238px;
    // @include consistent-margin-thingy(142px, 90px);
    @include consistent-margin-thingy(110px, 90px);
  }

  .title {
    font-size: px-em(20px);
    // @include consistent-margin-thingy(100px, 83px);
    @include consistent-margin-thingy(40px, 83px);
    margin-bottom: 0;
    font-weight: $bodyFontBoldWeight;
  }
  .copy {
    // margin-bottom: 60px;
    line-height: 1.7;
    font-size: 20px;
    margin-bottom: 0;
    margin-top: calc(var(--vh, 1vh) * 2);
    line-height: 1.4;
    // &-1 {
    //   margin-bottom: calc(var(--vh, 1vh) * 2);
    // }
    // &-2 {
    //   line-height: 1.4;
    // }
  }
  .buttons {
    position: absolute;
    width: 100%;
    bottom: 58px;
  }
  .button {
    margin-top: 30px;
    // width: calc(100% - 50px);
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-image: url(./../assets/images/close-x@2x.png);
    width: 40px;
    height: 40px;
    background-size: 23px;
    background-position: center center;
    background-repeat:no-repeat;

  }
  // @media only screen and (max-width: 414px) and (max-height: 736px) {

  //   .title {
  //     font-size: px-em(28px);
  //   }
  //   .copy {
  //     font-size: 18px;
  //   }
  //   .buttons {
  //     bottom: 15px;
  //   }
  //   .button {
  //     margin-top: 14px;
  //   }
  // }

  video {
    width: calc(100% - 90px);
    @include consistent-margin-thingy(75px, 182px);
    background-color: black;
  }

  .subhead {
    margin-top: 12px;
  }
}